#navbarWrapper {
  width: 100%;
  height: 12vh;
  transition: transform 0.3s;
  background-color: var(--myBlack);
  position: fixed;
  z-index: 1;
  padding: 0;
}

.navbarClosed {
  transform: translateY(-150%);
}

img {
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  pointer-events: none;
}

.myNavbar {
  margin: 0;
  padding: 0 1rem 0 0;
  place-self: center;
}

.myNavbar a:not(.homeLink) {
  margin-right: 1rem;
  text-decoration: none;
  color: white;
  display: inline-block;
  /* padding-bottom: 2px; */
  align-self: center;
  background-image: linear-gradient(var(--myBlue) 0 0);
  background-position: 0% 100%; /* OR bottom left */
  background-size: 0% 2px;
  background-repeat: no-repeat;
  transition: background-size 0.3s, background-position 0s 0.3s; /*change after the size immediately*/
}

.myNavbar a:hover {
  background-position: 100% 100%; /* OR bottom right */
  background-size: 100% 2px;
}

.logo {
  height: 100%;
  width: 3rem;
  margin: 0 1rem 0 1rem;
}

.navNum {
  color: var(--myBlue);
}

.resumeButton {
  color: white;
  border: 2px solid var(--myBlue);
  padding: 0.2rem 1rem;
  border-radius: 0.5rem;
  background-color: transparent;
  background-image: url('../img/buttonfill.png');
  background-repeat: repeat-x;
  background-position: 0 -100%;
  transition: 1.5s ease;
  width: max-content;
  height: max-content;
  align-self: center;
}

.resumeButton:hover {
  background-position: center;
}

.navbar-collapse {
  background-color: var(--myBlack);
}

.languageSelection {
  align-self: center;
  margin-right: 1rem;
}

.languageSelection button{
  background-color: var(--myBlack);
  padding: 0.2rem 0.5rem;
  border: 2px solid var(--myBlue);
  border-radius: 0.5rem;
  color: white;
  font-size: inherit !important;
}

.countryFlag {
  width: 2rem;
  height: 100%;
  margin: 0 1.5rem 0 1.5rem;
}

.ReactFlagsSelect-module_selectBtn__19wW7:after {
  border-top: 5px solid white;
}

.ReactFlagsSelect-module_selectBtn__19wW7[aria-expanded="true"]:after{
  border-bottom: 5px solid white;
}