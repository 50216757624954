.footerWrapper {
    color: white;
}

/* Splitter styling */

.splitWrapper {
    position: relative;
    padding-top: 5rem;
}

.split {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.split svg {
    position: relative;
    display: block;
    width: 100%;
    height: 150px;
}

.split .shape-fill {
    fill: #2B7FFF;
}