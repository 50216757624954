/* General settings */

@font-face {
  src: url("./fonts/Rubik-VariableFont_wght.ttf");
  font-family: Rubik;
}

@font-face {
  src: url("./fonts/Urbanist-VariableFont_wght.ttf");
  font-family: Urbanist;
}

:root {
  --myBlue: #2b7fff;
  --myBlack: #242731;
  --myWhite: #d9d9d9;
}

*,
*::after,
*::before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  font-size: 20px;
  scroll-behavior: smooth;
}

body {
  background-color: var(--myBlack);
  font-family: Urbanist, Rubik, sans-serif;
}

p {
  margin: 0;
}

::-webkit-scrollbar {
  width: 1em;
}

::-webkit-scrollbar-track {
  background: hsl(226, 15%, 17%);
}

::-webkit-scrollbar-thumb {
  background: var(--myBlue);
  border-radius: 100vw;
}

::-webkit-scrollbar-thumb:hover {
  background: hsl(216, 100%, 38%);
}

.dir-rtl {
  direction: rtl;
}

.nav-rtl {
  direction: rtl;
}

.dir-ltr {
  direction: ltr;
}

.text-right {
  text-align: right;
}

.fit-content {
  width: fit-content;
}

/* End of general settings */
