/* .split2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
}

.split2 svg {
    position: relative;
    display: block;
    width: 100%;
    height: 150px;
} */

.split2 .shape-fill {
    fill: #2B7FFF;
}

.contactDetailsWrapper {
    padding: 2rem 1rem;
    justify-content: center;
}

.contactMe p {
    color: var(--myBlue);
    font-weight: 900;
    font-size: 3rem;
    text-align: center;
}