/* @media (max-width: 321px){
    html{
        font-size: 18px;
    }
}

@media (max-width: 241px){
    html{
        font-size: 13px;
    }
} */

/* small fix for brand and hamburger button on mobile */
.navbar-brand,
.navbar-toggler {
  margin-right: var(--bs-navbar-brand-margin-end);
  margin-left: var(--bs-navbar-brand-margin-end);
}

.resumeButton {
  margin-right: var(--bs-navbar-brand-margin-end);
}

/* Medium devices (tablets, less than 992px) */
@media (max-width: 991.98px) {
  .logo {
    margin: 0;
    padding: 0.2rem;
  }

  .myName {
    font-size: 4.5rem;
  }

  .nameImgWrapper {
    text-align: center;
    vertical-align: middle;
    padding-top: 30vh;
    width: auto;
  }

  .nameWrapper {
    text-align: center;
    width: auto;
    margin: 0;
  }

  .svgGraphic {
    align-self: center;
    height: 15rem;
    width: 70%;
  }

  .aboutMeWrapper {
    text-align: center;
    text-align: -webkit-center;
  }

  .languagesList {
    justify-content: center;
    padding-left: 2rem;
  }

  .projectTextWrapper {
    text-align: center;
  }

  .projectImgWrapper {
    width: unset;
  }

  .projectTextWrapper {
    width: unset;
  }

  .projectImg {
    height: 100%;
    width: 12rem;
    margin: 0;
  }

  .navbarWrapper {
    text-align: center;
  }

  .platformLink {
    width: 100%;
  }

  .contactMe p {
    font-size: 2rem;
  }

  .platformImg {
    margin: 0;
  }
}

/* horizontal mode / tablets */
@media (min-width: 667px) and (max-width: 1023px) {
  .navbarWrapper {
    height: 10vh;
  }

  .nameImgWrapper {
    display: flex;
    padding: 0;
  }

  .svgGraphic {
    width: 40vw;
    height: 8rem;
  }

  .myName {
    font-size: 4rem;
  }

  .myTitle {
    font-size: 1rem;
  }

  .wave svg {
    height: 4rem;
  }

  .aboutMeWrapper {
    text-align: initial;
    text-align: -webkit-initial;
  }

  .TextAndImgWrapper {
    display: flex;
    padding: 4rem 1rem 4rem 1rem;
  }

  .languagesList {
    justify-content: initial;
    padding-left: 0;
  }

  .aboutMeTitle {
    font-size: 2.5rem;
  }

  .aboutMeText p,
  .languagesList ul {
    font-size: 1rem;
  }
}

/* wave wave styling */

.waveWrapper {
  position: relative;
}

.wave {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  overflow: hidden;
  line-height: 0;
}

.wave svg {
  position: relative;
  display: block;
  width: calc(175% + 1.3px);
  height: 20vh;
}

.wave .shape-fill {
  fill: var(--myWhite);
}

.darkWave .shape-fill {
  fill: var(--myBlack);
}

/** For tablet devices **/
@media (min-width: 768px) and (max-width: 1023px) {
  .wave svg {
    width: calc(175% + 1.3px);
    height: 10rem;
  }
}

/** For mobile devices (old size: 767px) **/
@media (max-width: 991px) {
  .wave svg {
    width: calc(175% + 1.3px);
    height: 20vh;
  }

  .myNavbar a,
  .resumeButton {
    width: max-content;
    font-size: 1.5rem;
  }

  .navMobileBG {
    height: 100vh;
    align-items: center;
    padding-top: 1rem;
  }

  .resumeButton {
    margin: var(--bs-nav-link-padding-y) var(--bs-nav-link-padding-x);
  }

  #navbarWrapper {
    height: auto;
    padding: 0.5rem 0;
  }

  .nav-rtl {
    direction: ltr;
  }

  .resumeButton {
    margin-bottom: 1.5rem;
  }

  .text-right {
    text-align: unset;
  }

  .fit-content {
    width: unset;
  }
}
