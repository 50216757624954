.nameImgWrapper {
    height: 100vh;
}

.nameWrapper {
    line-height: 175%;
    align-self: center;
    width: 25rem;
    text-align: left;
}

.myName {
    color: var(--myBlue);
    font-weight: 900;
    font-size: 5rem;
    margin: 0;
    height: 2.7rem;
}

.myTitle {
    color: var(--bs-code-color);
    font-size: 1.3rem;
    font-weight: normal;
    padding-left: 0.3rem;
    margin: 0;
}

.svgGraphic {
    height: 100%;
    width: 25rem;
    align-self: center;
}