.projectsWrapper {
    justify-content: center;
}

.projectsTitle {
    font-size: 3rem;
    font-weight: 900;
    color: var(--myBlue);
    text-align: center;
}

.projectDisclaimerWrapper {
    width: 75vw;
    justify-content: center;
}