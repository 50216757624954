.NotFoundWrapper {
    height: 100vh;
}

.NotFound {
    color: white;
    font-size: 5rem;
    padding-top: 25vh;
}

.homeLink {
    color: var(--myBlue);
    font-size: 2.5rem;
}

/* Mobile responsiveness */

@media (max-width: 991.98px) {
    .NotFound {
        font-size: 3rem;
        padding-top: 15vh;
    }
    
    .homeLink {
        font-size: 1.5rem;
    }
}
