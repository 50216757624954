.aboutMeWrapper {
    background-color: var(--myWhite);
    width: 100%;
    height: 100%;
    margin: 0;
}

#aboutme .TextAndImgWrapper {
    padding-bottom: 10rem; /* This will set the dark wave in its proper position */
}

.aboutMeText {
    width: 100%;
}

.aboutMeTitle {
    font-size: 3rem;
    font-weight: 900;
    width: 100%;
    color: var(--myBlack);
}

.aboutMeTitleLine {
    margin: 0.5rem 0 1rem 0;
    border-top: 1px solid;
    opacity: 1;
    color: rgba(0,0,0,0.75);
}

.aboutMeText {
    min-height: 10rem;
}

.aboutMeText p, .languagesList ul {
    font-size: 1.3rem;
    color: var(--myBlack);
}

.hobbiesText {
    min-height: 5rem;
}

.languagesList {
    direction: ltr;
    width: max-content;
}

.languagesList ul {
    margin-left: -1rem;
    padding-right: 1rem;
}