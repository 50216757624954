.projectDisplayWrapper {
    margin-right: 1rem;
    text-align: center;
    margin-bottom: 3rem;
}

.projectImg {
    height: 100%;
    width: 25rem;
}

.projectImgVertical {
    width: 10rem;
    height: 100%;
}

.projectImgWrapper {
    width: 100%;
}

.projectTextWrapper {
    text-align: right;
    width: 100%;
    align-self: center;
}

.projectName {
    font-size: 2rem;
    color: white;
    text-align: center;
}

.projectDesc {
    font-size: 1rem;
    color: white;
    text-align: center;
}