.platformImg {
    height: 100%;
    width: 3.5rem;
    margin: 0 1rem;
}

.contactPlatformsWrapper a{
    text-decoration: none;
}

.platformNameWrapper {
    margin-right: 1rem;
    align-self: center;
    text-align: center;
    font-size: 1.5rem;
    text-decoration: none;
    color: white;
    display: inline-block;
    padding-bottom:2px;
    background-image: linear-gradient(var(--myBlue) 0 0);
    background-position: 0% 100%; /* OR bottom left */
    background-size: 0% 2px;
    background-repeat: no-repeat;
    transition: background-size 0.3s, background-position 0s 0.3s; /*change after the size immediately*/
  }
  
.platformNameWrapper:hover {
    background-position: 100% 100%; /* OR bottom right */
    background-size: 100% 2px;
}